import React from 'react';
import Tab from './shared/Tab';

const WhatWeDoTabContent = [
    {
        tabName: 'Enterprise Applications',
        topTabText: null,
        bottomTabText: null,
        cardItems: [
            {
                cardTitle: 'Microsoft Platforms',
                subTitle: null,
                cardList: [
                    'ERP system',
                    'CRM system',
                    'HR system',
                    'Microsoft 365'
                ],
                cardTypeSmall: true,
                cardListColumn: null
            },
            {
                cardTitle: 'SAP Platforms',
                subTitle: null,
                cardList: [
                    'On Premise ERP Projects',
                    'SAP HANA',
                    'Human Capital Management',
                    'BI technologies and solutions'
                ],
                cardTypeSmall: true,
                cardListColumn: null
            },
            {
                cardTitle: 'ECM & DMS',
                subTitle: null,
                cardList: [
                    'Digital transformation',
                    'Enterprise Content Management',
                    'Document Management Systems',
                    'Product data management'
                ],
                cardTypeSmall: true,
                cardListColumn: null
            },
            {
                cardTitle: 'BI and Performance Management',
                subTitle: null,
                cardList: [
                    'SAP',
                    'SAS',
                    'IBM',
                    'Oracle',
                    'Microsoft',
                    'Tableau',
                    'Qlik',
                    'Anaplan'
                ],
                cardTypeSmall: false,
                cardListColumn: 'two'
            },
            {
                cardTitle: 'Artificial Intelligence (AI)',
                subTitle: null,
                cardList: [
                    'Application of AI in practice',
                    'Powerful GPU cluster for AI computing',
                    'Machine learning',
                    'AI-Driven Chatbots'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            },
        ]
    },
    {
        tabName: 'IT Infrastructure',
        topTabText: null,
        bottomTabText: null,
        cardItems: [
            {
                cardTitle: 'Hardware and Software supplies',
                subTitle: 'Partnership with world’s leading manufacturers',
                cardList: [
                    'HP',
                    'Dell',
                    'Lenovo',
                    'Fujitsu',
                    'Cisco',
                    'IBM',
                    'SAP',
                    'Oracle',
                    'Microsoft',
                    'Salesforce',
                    'AWS',
                    'etc...'
                ],
                cardTypeSmall: false,
                cardListColumn: 'tree'
            },
            {
                cardTitle: 'Data centres',
                subTitle: null,
                cardList: [
                    'Data centres architecture',
                    'Server virtualization and consolidation',
                    'Backup, replication and data recovery',
                    'High availability and Disaster recovery for DC’s',
                    'Networking'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            },
            {
                cardTitle: 'Enterprise networks',
                subTitle: null,
                cardList: [
                    'Network security',
                    'Software-defined networks',
                    'Branch office connectivity / remote access',
                    'Monitoring and analyses',
                    'Clustering: high availability and disaster recovery'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            },
            {
                cardTitle: 'Enterprise management',
                subTitle: null,
                cardList: [
                    'Management and security of endpoint devices',
                    'Server management and monitoring',
                    'Network management and monitoring',
                    'Application management and monitoring',
                    'Application and desktop virtualization'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            }
        ]
    },
    {
        tabName: 'Cloud Solutions',
        topTabText: null,
        bottomTabText: null,
        cardItems: [
            {
                cardTitle: 'Infrastructure as a service',
                subTitle: null,
                cardList: [
                    'Virtual data centres',
                    'Private cloud',
                    'Infrastructure for Enterprise applications'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            },
            {
                cardTitle: 'Data Backup and Recovery',
                subTitle: null,
                cardList: [
                    'Various Cloud backup service',
                    'Network attached storages',
                    'Cloud scenarios for disaster recovery'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            },
            {
                cardTitle: 'Platform as a service',
                subTitle: null,
                cardList: [
                    'VMware platform implementation and management',
                    'Microsoft platform',
                    'OpenShift container platform',
                    'AI platforms',
                    'Cloud Foundry platform'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            },
            {
                cardTitle: 'Cloud architecture and management',
                subTitle: null,
                cardList: [
                    'Competence in AWS',
                    'Competence in MS Azure',
                    'Competence in Google cloud',
                    'Competence in IBM Cloud',
                    'Automation and management of the cloud services'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            }
        ]
    },
    {
        tabName: 'Software Development',
        topTabText: null,
        bottomTabText: null,
        cardItems: [
            {
                cardTitle: 'DevOps and CI/CD',
                subTitle: null,
                cardList: [
                    'CI/CD Pipelines',
                    'Application modernization',
                    'Infrastructure as Code (IaC)',
                    'Monitoring and Logging'
                ],
                cardTypeSmall: true,
                cardListColumn: null
            },
            {
                cardTitle: 'API Development',
                subTitle: null,
                cardList: [
                    'RESTful API Development',
                    'GraphQL Development',
                    'API Security',
                    'Third-Party API Integration'
                ],
                cardTypeSmall: true,
                cardListColumn: null
            },
            {
                cardTitle: 'UX & UI Design',
                subTitle: null,
                cardList: [
                    'UX Research and Analysis',
                    'Information Architecture',
                    'Responsive UI Design',
                    'Animation Design'
                ],
                cardTypeSmall: true,
                cardListColumn: null
            },
            {
                cardTitle: 'Application development',
                subTitle: null,
                cardList: [
                    'Web applications',
                    'Front / Back-end development',
                    'Mobile applications (iOS, Android)',
                    'TV applications (ROKU, tvOS)'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            },
            {
                cardTitle: 'Quality Assurance and Testing',
                subTitle: null,
                cardList: [
                    'Automated Testing',
                    'Manual Testing',
                    'Performance Testing',
                    'Security Testing'
                ],
                cardTypeSmall: false,
                cardListColumn: null
            }
        ]
    }
];

const WhatWeDo = () => {
    return (
        <section id="whatWeDo" className="section-light-dark">
            <div className="py-10">
                <div className="container">
                    <h2 className="mb-4 text-center">What we do</h2>
                    <br/><br/>
                    <Tab data={WhatWeDoTabContent}></Tab>
                </div>
            </div>
        </section>
    );
};

export default WhatWeDo;
