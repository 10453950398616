import React, { useState } from 'react';
import { useFormik } from 'formik';
import alt from '../img/alt.svg';
import map_pin from '../img/map-pin.svg';
import call_ringing from '../img/Call_Ringing.svg';
import * as Yup from 'yup';

const ContactForm = () => {
    const [showFlashMessage, setShowFlashMessage] = useState(false);
    const [flashMessageContent, setFlashMessageContent] = useState('');

    // Validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phone: Yup.string(),
        company: Yup.string(),
        message: Yup.string().required('Message is required')
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            company: '',
            message: ''
        },
        validationSchema,
        onSubmit: values => {
            fetch('https://east-it-c89f49a78f7f.herokuapp.com/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setShowFlashMessage(true);
                    setFlashMessageContent(data.message || 'Thank you! Your submission has been received!');
                })
                .catch(() => {
                    setShowFlashMessage(true);
                    setFlashMessageContent('Something went wrong, please try again!');
                });
        }
    });

    return (
        <section id="contacts" className="section-dark">
            <div className="container">
                <div className="py-10">
                    <h2 className="mb-5 text-center">Let’s work together</h2>
                    <div className="contactForm contactForm__wrapp">
                        <div>
                            <div className="row">
                                <div className="col-lg-5 order-lg-2">
                                    <h3 className="contactForm__title">Contact Info</h3>
                                    <div className="contactForm__box">
                                        <div>
                                            <a href="mailto:info@east-it.sk">
                                                <img src={alt} alt="alt"/>
                                                <span>info@east-it.sk</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="contactForm__box">
                                        <div>
                                            <a href="https://www.google.com/maps/search/?api=1&query=48.1686422,17.0754913&query_place_id=ChIJVcz9k3aMbEcR15fsynqqtXo"
                                               target="_blank">
                                                <img src={map_pin} alt="map_pin" />
                                                <span>Bratislava, Dúbravská cesta 1793/2, 841 04</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="contactForm__box">
                                        <div>
                                            <a href="tel:+421 945 503 881">
                                                <img src={call_ringing} alt="Call_Ringing"/>
                                                <span>+421 945 503 881</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 order-lg-1 pe-lg-5">
                                    <h3 className="contactForm__title">Write Us</h3>
                                    <form onSubmit={formik.handleSubmit} id="contactForm">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input className="form-control"
                                                       maxLength="256"
                                                       name="name"
                                                       data-name="Name"
                                                       placeholder="Name*"
                                                       id="name"
                                                       required=""
                                                       onChange={formik.handleChange}
                                                       value={formik.values.name}
                                                />
                                                <div className="error-message">
                                                    {formik.touched.name && formik.errors.name ?
                                                        <div>{formik.errors.name}</div> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <input id="email"
                                                       className="form-control"
                                                       maxLength="256"
                                                       name="email"
                                                       data-name="Email"
                                                       placeholder="Email*"
                                                       type="email"
                                                       required=""
                                                       onChange={formik.handleChange}
                                                       value={formik.values.email}
                                                />
                                                <div className="error-message">
                                                    {formik.touched.email && formik.errors.email ?
                                                        <div>{formik.errors.email}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input className="form-control"
                                                       name="phone"
                                                       data-name="Phone"
                                                       placeholder="Phone"
                                                       id="phone"
                                                       onChange={formik.handleChange}
                                                       value={formik.values.phone}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input className="form-control"
                                                       name="company"
                                                       data-name="Company"
                                                       placeholder="Company"
                                                       id="company"
                                                       onChange={formik.handleChange}
                                                       value={formik.values.company}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <textarea className="form-control"
                                                          rows="6"
                                                          maxLength="5000"
                                                          name="message"
                                                          data-name="Message"
                                                          placeholder="Message*"
                                                          id="message"
                                                          required=""
                                                          onChange={formik.handleChange}
                                                          value={formik.values.message}
                                                ></textarea>
                                                <div className="error-message">
                                                    {formik.touched.message && formik.errors.message ?
                                                        <div>{formik.errors.message}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit">Send</button>
                                        {showFlashMessage &&
                                            <h3 className="text-center mt-5">{flashMessageContent}</h3>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
