import React from 'react';
import Header from "./shared/Header";
import Lottie from 'react-lottie';
import animationData from '../lottie/waves.json';

const Hero = () => {
    // Function to detect if the browser is Safari
    const isSafari = () => {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        renderer: isSafari() ? 'svg' : 'canvas',
        rendererSettings: {
            preserveAspectRatio: 'none',
            progressiveLoad: true, // This can help with performance issues
            hideOnTransparent: true // Hide elements when they are transparent
        }
    };

    return (
        <section className="vh-100 hero-section">
            <div className="hero-animation" style={{backgroundImage: 'linear-gradient(to top, #010403, #313B4E)'}}>
                <Lottie
                    className="hero-animation"
                    options={defaultOptions}
                    height={700}
                    width={1441}
                />
            </div>
            <Header/>
            <div className="hero-section-content container position-relative z-1">
                <div className="py-10 text-center">
                    <h1>Technology Excellence,<br/>
                        From Eastern Europe, for the World.
                    </h1>
                </div>
            </div>
        </section>
    );
};

export default Hero;
