import React, { useEffect } from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import appStoreIcon from '../img/App-Store.png';
import gptIcon from '../img/gpt-icon.png';
import gptIphone from '../img/gpt-iPhone.png';
import transcribeIcon from '../img/transcribe-icon.png';
import transcribeIphone from '../img/transcribe-IPhone.png';
import translateIcon from '../img/translate-icon.png';
import translateIphone from '../img/translate-IPhone.png';
import 'slick-carousel';

const sliderItems = [
    {
        icon: gptIcon,
        title: 'AI Chat 4.0: Ask ChatGPT',
        description: `AI Chat 4.0 is an app for iPhone and iPad that leverages OpenAI's GPT-4 to offer dynamic AI features.
                      It allows users to create essays, translate texts, seek financial advice, and get book recommendations.
                      Explore new interactions with AI technology easily.`,
        appStoreLink: 'https://apps.apple.com/us/app/voice-translation-translator/id6449082545',
        iphoneScreen: gptIphone
    },
    {
        icon: transcribeIcon,
        title: 'Transcribe - Speech to Text',
        description: `Transcriber is a powerful app, offering advanced speech-to-text capabilities. It's perfect for
                      converting voice recordings into text during meetings or personal notes. The app supports multiple
                      languages and boasts high accuracy, thanks to AI technology.`,
        appStoreLink: 'https://apps.apple.com/us/app/translator-translate-voice/id6478541193',
        iphoneScreen: transcribeIphone
    },
    {
        icon: translateIcon,
        title: 'Translator – Translate Voice',
        description: `Translator is an advanced multi-functional translation app. It features voice, photo, text, and
                      camera translation capabilities, providing accurate and speedy translations in a variety of
                      languages. Ideal for travelers or anyone needing real-time translation.`,
        appStoreLink: 'https://apps.apple.com/us/app/transcribe-speech-to-text/id6479705159',
        iphoneScreen: translateIphone
    }
];

const OurProducts = () => {
    useEffect(() => {
        // // Initialize slick slider
        $('.app-slider').slick({
            centerMode: true,
            variableWidth: true,
            centerPadding: '16vw',
            slidesToShow: 1,
            prevArrow: '<button type="button" class="slick-prev"><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M27.0043 18.9483C27.8042 18.1865 27.835 16.9206 27.0733 16.1207C26.3115 15.3208 25.0456 15.29 24.2457 16.0517L27.0043 18.9483ZM11.1207 28.5517C10.3208 29.3135 10.29 30.5794 11.0517 31.3793C11.8135 32.1792 13.0794 32.21 13.8793 31.4483L11.1207 28.5517ZM13.8793 28.5517C13.0794 27.79 11.8135 27.8208 11.0517 28.6207C10.29 29.4206 10.3208 30.6865 11.1207 31.4483L13.8793 28.5517ZM24.2457 43.9483C25.0456 44.71 26.3115 44.6792 27.0733 43.8793C27.835 43.0795 27.8042 41.8135 27.0043 41.0517L24.2457 43.9483ZM12.5 28C11.3954 28 10.5 28.8954 10.5 30C10.5 31.1046 11.3954 32 12.5 32V28ZM47.5 32C48.6046 32 49.5 31.1046 49.5 30C49.5 28.8954 48.6046 28 47.5 28V32ZM24.2457 16.0517L11.1207 28.5517L13.8793 31.4483L27.0043 18.9483L24.2457 16.0517ZM11.1207 31.4483L24.2457 43.9483L27.0043 41.0517L13.8793 28.5517L11.1207 31.4483ZM12.5 32H47.5V28H12.5V32Z" fill="white"/>\n' +
                '</svg>\n</button>',
            nextArrow: '<button type="button" class="slick-next"><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M32.9957 41.0517C32.1958 41.8135 32.165 43.0794 32.9267 43.8793C33.6885 44.6792 34.9544 44.71 35.7543 43.9483L32.9957 41.0517ZM48.8793 31.4483C49.6792 30.6865 49.71 29.4206 48.9483 28.6207C48.1865 27.8208 46.9206 27.79 46.1207 28.5517L48.8793 31.4483ZM46.1207 31.4483C46.9206 32.21 48.1865 32.1792 48.9483 31.3793C49.71 30.5794 49.6792 29.3135 48.8793 28.5517L46.1207 31.4483ZM35.7543 16.0517C34.9544 15.29 33.6885 15.3208 32.9267 16.1207C32.165 16.9205 32.1958 18.1865 32.9957 18.9483L35.7543 16.0517ZM47.5 32C48.6046 32 49.5 31.1046 49.5 30C49.5 28.8954 48.6046 28 47.5 28V32ZM12.5 28C11.3954 28 10.5 28.8954 10.5 30C10.5 31.1046 11.3954 32 12.5 32V28ZM35.7543 43.9483L48.8793 31.4483L46.1207 28.5517L32.9957 41.0517L35.7543 43.9483ZM48.8793 28.5517L35.7543 16.0517L32.9957 18.9483L46.1207 31.4483L48.8793 28.5517ZM47.5 28L12.5 28V32L47.5 32V28Z" fill="white"/>\n' +
                '</svg>\n</button>',
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        centerPadding: '10vw',
                    }
                },
                {
                    variableWidth: false,
                    breakpoint: 991,
                    settings: {
                        centerMode: false,
                    }
                },
            ]
        })


        // Cleanup to prevent memory leak
        return () => {
            $('.app-slider').slick('unslick');
        };
    }, []);

    return (
        <section id="products" className="section-light-dark">
            <div className="py-10">
                <div className="container">
                    <h2 className="mb-5 text-center">We developed</h2>
                </div>
                <div className="app-slider">
                    {sliderItems.map((item, index) => (
                        <div key={index} className="appItem">
                            <div className="row align-items-end">
                                <div className="col-12 col-lg-7 appItem_content">
                                    <img className="appItem_icon" src={item.icon} alt={`${item.title}-icon`}/>
                                    <h5 className="appItem_title">{item.title}</h5>
                                    <div className="appItem_desc">{item.description}</div>
                                    <a className="appItem_AppStore" href={item.appStoreLink}>
                                        <img src={appStoreIcon} alt="App Store"/>
                                    </a>
                                </div>
                                <div className="col-md-5 p-0 d-none d-lg-flex align-items-center justify-content-end">
                                    <img className="appItem_iphoneScreen" src={item.iphoneScreen}
                                         alt={`${item.title}-img`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
};

export default OurProducts;
