import React from 'react';
import Tab from './shared/Tab';
import access_top_talent from '../../src/img/what_we_offer_icons/access_top_talent.svg';
import cost_efficiency from '../../src/img/what_we_offer_icons/cost_efficiency.svg';
import deployment_support from '../../src/img/what_we_offer_icons/deployment_support.svg';
import design_development from '../../src/img/what_we_offer_icons/design_development.svg';
import ensure_cultural_fit from '../../src/img/what_we_offer_icons/ensure_cultural_fit.svg';
import flexibility_scalability from '../../src/img/what_we_offer_icons/flexibility_scalability.svg';
import identify_attract_top_talent from '../../src/img/what_we_offer_icons/identify_attract_top_talent.svg';
import ongoing_support from '../../src/img/what_we_offer_icons/ongoing_support.svg';
import project_planning from '../../src/img/what_we_offer_icons/project_planning.svg';
import quality_assurance from '../../src/img/what_we_offer_icons/quality_assurance.svg';
import seamless_integration from '../../src/img/what_we_offer_icons/seamless_integration.svg';
import streamline_the_hiring_process from '../../src/img/what_we_offer_icons/streamline_the_hiring_process.svg';

const WhatWeOfferTabContent = [
    {
        tabName: 'Full Project Management',
        topTabText: 'We manage and deliver complete projects, providing an end-to-end experience that encompasses every stage of the development process. Whether it\'s developing a cutting-edge mobile application, a robust software platform, or any other IT project, we take full responsibility from conception to completion. Our dedicated team handles:',
        bottomTabText: 'With East-IT\'s full project management, you can trust that your project will be delivered on time, within budget,\n and to your complete satisfaction.',
        cardItems: [
            {
                cardTitle: 'Project Planning',
                cardTitleIcon: project_planning,
                subTitle: 'We work closely with you to understand your requirements, goals, and vision.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Design & Development',
                cardTitleIcon: design_development,
                subTitle: 'Our expert designers and developers create innovative, user-friendly solutions tailored to your needs.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Quality Assurance',
                cardTitleIcon: quality_assurance,
                subTitle: 'Rigorous testing ensures your project meets the highest standards of quality and performance.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Deployment & Support',
                cardTitleIcon: deployment_support,
                subTitle: 'We oversee the deployment process and provide ongoing support to ensure your solution operates smoothly.',
                cardTypeSmall: false,
            }
        ]
    },
    {
        tabName: 'IT Service Outsourcing',
        topTabText: 'We offer IT service outsourcing, providing skilled employees who work on your projects remotely. \nThis model allows you to:',
        bottomTabText: 'By choosing East-IT\'s off-shoring services, you gain a flexible and efficient solution \n' +
            'to augment your in-house capabilities and meet project deadlines effectively.',
        cardItems: [
            {
                cardTitle: 'Access Top Talent',
                cardTitleIcon: access_top_talent,
                subTitle: 'Leverage the expertise of our highly skilled professionals without the need to hire full-time staff.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Cost Efficiency',
                cardTitleIcon: cost_efficiency,
                subTitle: 'Benefit from reduced labor costs while maintaining high-quality standards.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Seamless Integration',
                cardTitleIcon: seamless_integration,
                subTitle: 'Our employees integrate seamlessly into your team, ensuring collaboration and continuity.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Flexibility and Scalability',
                cardTitleIcon: flexibility_scalability,
                subTitle: 'Scale your team up or down based on project demands and timelines.',
                cardTypeSmall: false,
            }
        ]
    },
    {
        tabName: 'Talent Acquisition',
        topTabText: 'We help you find the right candidates to hire directly into your organization. \nOur talent acquisition services are designed to:',
        bottomTabText: 'By choosing East-IT\'s off-shoring services, you gain a flexible and efficient solution \n' +
            'to augment your in-house capabilities and meet project deadlines effectively.',
        cardItems: [
            {
                cardTitle: 'Identify & Attract Top Talent',
                cardTitleIcon: identify_attract_top_talent,
                subTitle: 'We leverage our extensive network and industry expertise to find candidates that match your specific requirements.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Streamline the Hiring Process',
                cardTitleIcon: streamline_the_hiring_process,
                subTitle: 'From screening and interviewing to final selection, we handle the entire recruitment process to save you time and effort.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Ensure Cultural Fit',
                cardTitleIcon: ensure_cultural_fit,
                subTitle: 'We prioritize finding candidates who not only have the necessary skills but also fit well with your company culture and values.',
                cardTypeSmall: false,
            },
            {
                cardTitle: 'Ongoing Support',
                cardTitleIcon: ongoing_support,
                subTitle: 'We provide support throughout the onboarding process to ensure a smooth transition for the new hire.',
                cardTypeSmall: false,
            }
        ]
    },
]

const WhatWeOffer = () => {
    return (
        <section id="whatWeOffer" className="section-dark">
            <div className="py-10">
                <div className="container">
                    <h2 className="mb-4 text-center">What we offer</h2>
                    <br/><br/>
                    <Tab data={WhatWeOfferTabContent}></Tab>
                </div>
            </div>
        </section>
    );
};

export default WhatWeOffer;
