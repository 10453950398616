import React from 'react';

const Footer = () => {
    return (
        <footer className="section-light-dark">
            <div className="footer-copyright">© 2024 East IT, All rights reserved</div>
        </footer>
    );
};

export default Footer;
