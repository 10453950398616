import React from 'react';
import arrow_path from '../img/arrow.svg';

const About = () => {
    return (
        <section id="about" className="section-dark">
            <div className="container">
                <div className="py-10">
                    <h2 className="mb-4 text-center">Who we are</h2>
                    <p className="subtitle text-center">IT company that develops mobile applications, desktop versions
                        and web products.</p>
                    <div className="d-none d-lg-block"><br/><br/></div>
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="simpleCards">
                                <div className="simpleCards_content">
                                    <div className="simpleCards_number">
                                        20+&nbsp;<img src={arrow_path} alt="arrow"/>
                                    </div>
                                    <div className="simpleCards_text">successful<br/> mobile & TV apps</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="simpleCards">
                                <div className="simpleCards_content">
                                    <div className="simpleCards_number">
                                        2 years&nbsp;<img src={arrow_path} alt="arrow"/>
                                    </div>
                                    <div className="simpleCards_text">of expertise and analysis</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="simpleCards">
                                <div className="simpleCards_content">
                                    <div className="simpleCards_number">
                                        1m+&nbsp;<img src={arrow_path} alt="arrow"/>
                                    </div>
                                    <div className="simpleCards_text">downloads of applications</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
