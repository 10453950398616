import React, { useState, useEffect, useRef } from 'react';
import logo from '../../img/logo.png';
import toolbar from '../../img/Toolbar.png';

const Header = () => {
    const [activeLink, setActiveLink] = useState('about');
    const [showMenu, setShowMenu] = useState(null);
    const [lastScrollY, setLastScrollY] = useState(0);
    const sectionsRef = useRef({});

    const controlMenu = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY === 0) {
                setShowMenu(null);
            } else if (window.scrollY > lastScrollY && window.scrollY > 200) {
                setShowMenu(false);
            } else if (window.scrollY < lastScrollY && window.scrollY > 200) {
                setShowMenu(true);
            }
            setLastScrollY(window.scrollY);
        }
    };

    const handleActiveItem = (newActiveLink) => {
        setActiveLink(newActiveLink);
        const element = document.getElementById(newActiveLink);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    var navLinks = document.getElementsByClassName('nav-link');
    for (var i = 0; i < navLinks.length; i++) {
        navLinks[i].addEventListener('click', function () {
            var collapses = document.querySelectorAll('.collapse');
            collapses.forEach(function (collapse) {
                collapse.classList.remove('show');
            });
        });
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlMenu);
            return () => {
                window.removeEventListener('scroll', controlMenu);
            };
        }
    }, [lastScrollY]);

    useEffect(() => {
        const sections = ['about', 'whatWeDo', 'whatWeOffer', 'products', 'contacts'];
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveLink(entry.target.id);
                    }
                });
            },
            { threshold: 0.5 }
        );

        sections.forEach((id) => {
            const section = document.getElementById(id);
            if (section) {
                observer.observe(section);
                sectionsRef.current[id] = section;
            }
        });

        return () => {
            if (sectionsRef.current) {
                sections.forEach((id) => {
                    const section = sectionsRef.current[id];
                    if (section) {
                        observer.unobserve(section);
                    }
                });
            }
        };
    }, []);

    let menuClass = '';
    if (showMenu === null) {
        menuClass = ''; // No class
    } else if (showMenu) {
        menuClass = 'show';
    } else {
        menuClass = 'hide';
    }

    return (
        <nav className={`navbar navbar-expand-lg z-2 fixed-menu ${menuClass}`}>
            <div className="container">
                <a className="navbar-brand" href="#">
                    <img className="logo" src={logo} alt="logo" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <button
                        id="close-menu"
                        className="btn-close btn-close-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Close"
                    ></button>
                    <ul
                        className="navbar-nav"
                        style={{backgroundImage: `url(${toolbar})`}}
                    >
                        <li className="nav-item">
                            <a className={`nav-link ${activeLink === 'about' ? 'active' : ''}`}
                               onClick={() => handleActiveItem('about')} href="#about">
                                About us
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeLink === 'whatWeDo' ? 'active' : ''}`}
                               onClick={() => handleActiveItem('whatWeDo')} href="#whatWeDo">
                                What we do
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeLink === 'whatWeOffer' ? 'active' : ''}`}
                               onClick={() => handleActiveItem('whatWeOffer')} href="#whatWeOffer">
                                What we offer
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeLink === 'products' ? 'active' : ''}`}
                               onClick={() => handleActiveItem('products')} href="#products">
                                Products
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeLink === 'contacts' ? 'active' : ''}`}
                               onClick={() => handleActiveItem('contacts')} href="#contacts">
                                Contacts
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;
