import React, { useState } from 'react';

const Tab = ({data}) => {
    const [activeTab, setActiveTab] = useState(data[0].tabName);

    const listCol = (col) => {
        if (col === 'two') {
            return 'col-two'
        } else if (col === 'tree') {
            return 'col-tree'
        } else {
            return 'col-one'
        }
    }

    return (
        <div>
            <div className="nav-tabs-wrapp">
                <ul className={`nav nav-tabs`}>
                    {data.map((tab, index) => (
                        <li className="nav-item" key={index}>
                            <button
                                className={`nav-link ${activeTab === tab.tabName ? 'active' : ''}`}
                                onClick={() => setActiveTab(tab.tabName)}
                            >
                                {tab.tabName}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="tab-content">
                {data.map((tab, index) => (
                    <div
                        className={`tab-pane fade ${activeTab === tab.tabName ? 'show active' : ''}`}
                        key={index}
                    >
                        <div className="tabText tabTopText">{tab.topTabText}</div>
                        <div className="row">
                            {tab.cardItems.map((card, cardIndex) => (
                                <div
                                    className={`cardItem ${card.cardTypeSmall ? 'cardSmall col-lg-4' : 'cardBig col-lg-6'}`}
                                    key={cardIndex}>
                                    <div className="simpleCards">
                                        <div className="simpleCards_content">
                                            <div className="card-header">
                                                {card.cardTitleIcon &&
                                                    <img src={card.cardTitleIcon} alt={`cardTitleIcon_${cardIndex}`}/>}
                                                {card.cardTitle}
                                            </div>
                                            <div className="card-body">
                                                {card.subTitle &&
                                                    <h3 className="card-subtitle">{card.subTitle}</h3>
                                                }
                                                {card.cardList &&
                                                    <ul className={listCol(card.cardListColumn)}>
                                                        {card.cardList.map((item, itemIndex) => (
                                                            <li key={itemIndex}>{item}</li>
                                                        ))}
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="tabText">{tab.bottomTabText}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tab;
