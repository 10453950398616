import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import ContactForm from './components/ContactForm';
import Hero from './components/Hero';
import About from './components/About';
import WhatWeDo from './components/WhatWeDo';
import WhatWeOffer from './components/WhatWeOffer';
import OurProducts from './components/OurProducts';
import useGoogleFont from './hooks/useGoogleFont';
import Footer from "./components/shared/Footer";

function App() {
    useGoogleFont('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

    return (
    <div className="App">
        <Hero />
        <About />
        <WhatWeDo />
        <WhatWeOffer />
        <OurProducts />
        <ContactForm />
        <Footer />
    </div>
  );
}

export default App;
